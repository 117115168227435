import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Checkbox, DatePicker, FlexRow, Icon, SingleSelectUser, Text } from '../components-reusable'
import { useOnClickOutside } from '../components-reusable/hooks/index.js'
import Identifier from './Identifier.js'
import ListViewRowAssignee from './ListViewRowAssignee'
import {
    AdditionalInformation,
    CanvasSheetPill,
    CreatedOnLabel,
    DueDatePill,
    LastUpdatedLabel,
} from './ListViewTaskRowElements'
import StatusDot from './StatusDot.js'
import TagsPill from './TagsPill'

const StyledListRow = styled(FlexRow, {
    alignItems: 'center',
    minHeight: '48px',
    background: '$neutral10',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '12px',
    borderBottom: '1px solid $neutral07',
    zIndex: 2,
    transitionDuration: '0.4s',
    '&:hover': {
        background: '$primary02',
        cursor: 'pointer',
    },
})

const StyledCollaborationNameContainer = styled(FlexRow, {
    alignItems: 'center',
    justifyContent: 'start',
    width: 0,
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 12,
    minWidth: 350,
})

const StyledCollaborationName = styled(Text, {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '$neutral04',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledCheckbox = styled(Checkbox, {
    marginRight: '12px',
})

const SecondaryText = styled(Text, {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '$neutral05',
})

const RowCell = React.forwardRef(({ cellWidth, children, css }, ref) => (
    <FlexRow ref={ref} css={{ ...css, width: cellWidth, minWidth: cellWidth, maxWidth: cellWidth }}>
        {children}
    </FlexRow>
))

const FallbackCell = ({ iconName, text, onClick, dataCy }) => {
    return (
        <FlexRow
            data-cy={dataCy}
            onClick={e => {
                if (onClick) {
                    e.stopPropagation()
                    onClick(e)
                }
            }}
            css={{ alignItems: 'center' }}
        >
            {iconName && <Icon name={iconName} iconSize="12px" css={{ marginRight: '8px' }} shape="circle" />}
            <SecondaryText>{text}</SecondaryText>
        </FlexRow>
    )
}

// ListTaskRow component
const ListViewTaskRow = ({
    assignee,
    assigneeIsOnline,
    canvasId,
    date,
    dueDate,
    geometry,
    id,
    identifier,
    isArchived,
    mostRecentUpdateDate,
    name,
    onGoToCanvasId,
    onItemSelect,
    sheetLabel,
    showFullContent,
    statusColor,
    statusIsCompleted,
    statusLabel,
    tagNames,
    isChecked,
    handleItemChecked,
    onDateChange,
    onAssigneeChange,
    allAvailableAssigneesShapes,
    projectIdentifier,
    taskRowCheckboxDataId,
}) => {
    const [showDatePicker, setShowDatePicker] = React.useState()
    const [showAssigneeDropdown, setShowAssigneeDropdown] = React.useState()

    const dueDateRow = React.useRef()
    const assigneeCell = React.useRef()

    useOnClickOutside(dueDateRow, () => setShowDatePicker(false))
    useOnClickOutside(assigneeCell, () => setShowAssigneeDropdown(false))

    const singleSelectUserProps = {
        projectParticipantShapes: allAvailableAssigneesShapes,
        selectedUser: assignee,
        setSelectedUserId: userId => {
            onAssigneeChange({ userId, collaborationId: id })
            setShowAssigneeDropdown(false)
        },
    }

    const renderIdentifierCell = () =>
        identifier && (
            <Identifier
                projectIdentifier={projectIdentifier}
                collaborationIdentifier={identifier}
                variant="medium"
                noPrefix
                css={{ fontWeight: '400' }}
            />
        )

    const renderAssigneeCell = () => {
        if (showAssigneeDropdown)
            return (
                <SingleSelectUser
                    withoutIcon
                    withNoAssigneeOption
                    css={{ height: '40px' }}
                    {...singleSelectUserProps}
                />
            )
        if (assignee)
            return (
                <ListViewRowAssignee
                    participantShape={assignee}
                    isOnline={assigneeIsOnline}
                    onClick={e => {
                        e.stopPropagation()
                        setShowAssigneeDropdown(val => !val)
                    }}
                />
            )

        return (
            <FallbackCell
                dataCy="list-view-assignee"
                iconName="user"
                text="Add Assignee"
                onClick={() => setShowAssigneeDropdown(val => !val)}
            />
        )
    }

    const isNameEmpty = name.length === 0
    return (
        <StyledListRow data-cy="list-view-task-row" className="list-view__task-row" onClick={() => onItemSelect(id)}>
            <FlexRow css={{ flex: '1', alignItems: 'center' }}>
                <StyledCheckbox
                    size="md"
                    defaultChecked={true}
                    checked={isChecked}
                    onClick={e => e.stopPropagation()} // do not bubble, so the row is not selected
                    onCheckedChange={() => handleItemChecked(id)}
                    data-id={taskRowCheckboxDataId}
                />
                <StatusDot
                    {...{ isArchived, isCompleted: statusIsCompleted, isTask: true, statusColor, statusLabel }}
                />
                <StyledCollaborationNameContainer>
                    <StyledCollaborationName empty={isNameEmpty.toString()} data-cy="list-view-task-name">
                        {isNameEmpty ? 'Untitled task' : name}
                    </StyledCollaborationName>
                    <LastUpdatedLabel mostRecentUpdateDate={mostRecentUpdateDate} />
                </StyledCollaborationNameContainer>
            </FlexRow>
            <FlexRow css={{ gap: '12px', alignItems: 'center' }}>
                <RowCell cellWidth="100px">{renderIdentifierCell()}</RowCell>
                <RowCell cellWidth="180px" ref={assigneeCell}>
                    {renderAssigneeCell()}
                </RowCell>
                <RowCell cellWidth="140px" ref={dueDateRow}>
                    {dueDate ? (
                        <DueDatePill
                            dueDate={dueDate}
                            isCompleted={statusIsCompleted}
                            isArchived={isArchived}
                            onClick={e => {
                                e.stopPropagation()
                                setShowDatePicker(val => !val)
                            }}
                        />
                    ) : (
                        <FallbackCell
                            dataCy="list-view-due-date"
                            iconName="calendar"
                            text="Add Due Date"
                            onClick={() => setShowDatePicker(val => !val)}
                        />
                    )}
                    {showDatePicker && (
                        <Box css={{ position: 'absolute', mt: '28px', zIndex: 200 }}>
                            <DatePicker
                                onChange={dueDate => {
                                    onDateChange({ itemId: id, dueDate })
                                    setShowDatePicker(false)
                                }}
                                value={dueDate}
                            />
                        </Box>
                    )}
                </RowCell>
                <RowCell cellWidth="250px">
                    <CanvasSheetPill sheetLabel={sheetLabel} onClick={() => onGoToCanvasId(id)} />
                </RowCell>
                {showFullContent && (
                    <>
                        <RowCell cellWidth="60px">
                            <TagsPill.Simple tagNames={tagNames} />
                        </RowCell>
                        <RowCell cellWidth="30px">
                            <AdditionalInformation geometry={geometry()} canvasId={canvasId} />
                        </RowCell>
                        <RowCell cellWidth="100px">
                            <CreatedOnLabel createdOn={date} />
                        </RowCell>
                    </>
                )}
            </FlexRow>
        </StyledListRow>
    )
}

ListViewTaskRow.propTypes = {
    assignee: PropTypes.object,
    assigneeIsOnline: PropTypes.bool,
    date: PropTypes.object, // Date
    dueDate: PropTypes.object, // Date
    mostRecentUpdate: PropTypes.object, // Update object
    name: PropTypes.string,
    sheetLabel: PropTypes.string,
    showFullContent: PropTypes.bool,
    statusLabel: PropTypes.string,
    statusColor: PropTypes.string,
    tagNames: PropTypes.arrayOf(PropTypes.string),
    projectIdentifier: PropTypes.string,
    identifier: PropTypes.string,
}

export default ListViewTaskRow
