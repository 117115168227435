/*
 * AdminViewSubscriptionBlock: Display the subscription status of the current Organization:
 *
 * - which plan
 * - how much / month
 * - how many seats you've paid and how many you're currently using
 * - a Manage button to change your subscription
 *
 * AdminViewManageSubscriptionModal: modal to change your seat count up or down
 *
 * Clicking on "Manage" in the AdminViewSubscriptionBlock will show the AdminViewManageSubscriptionModal
 *
 */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import Icon from '../components-reusable/Icon.js'
import { Box, Button, FlexColumn, FlexRow, IconButtonWithTooltip, Text } from '../components-reusable/index.js'
import { formatUsd, pricingMetrics, RangePlans } from '../helpers.js'
import AdminViewUpgradePage from './AdminViewUpgradePage.js'
import { StyledModalActions, StyledModalContainer, StyledOverlay, StyledSecondaryModalLabel } from './Modal.js'
// ---------------------------------------------------------------------------------------------------------------------
// AdminViewManageSubscriptionModal
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Modal to change your subscription: - count +
 * Includes buttons for Downgrade Plan, Cancel and Confirm
 *
 * @sig AdminViewManageSubscriptionModal :: ({ String, Number, Number, onCancel, onConfirm: int -> *, onDowngrade: () -> * }) => {
 */
const AdminViewManageSubscriptionModal = ({
    organizationName,
    currentSeatCount,
    assignedSeats,
    onCancel,
    onConfirm,
    onDowngrade,
}) => {
    const increment = () => setSeatCount(seatCount + 1)
    const decrement = () => {
        const canDecrement = seatCount > assignedSeats
        if (canDecrement) setSeatCount(seatCount - 1)
    }

    const renderDecrementButton = () => {
        const cantDecrement = seatCount <= assignedSeats
        const color = cantDecrement ? '$neutral05' : '$neutral04'
        const tooltip = cantDecrement
            ? 'To reduce your total seat count, you must first unassign any members from the seats you’d like to remove. Do this by suspending Admins and Collaborators or cancelling existing invitations.'
            : 'Fewer seats'
        return (
            <IconButtonWithTooltip
                disabled={cantDecrement}
                side="bottom"
                size="38"
                iconName="zoomOut"
                tooltipText={tooltip}
                onClick={decrement}
                tooltipCss={{ maxWidth: 320 }}
                css={{
                    h: 40,
                    w: 40,
                    br: 999,
                    b: '1px solid $neutral07',
                    bg: '$neutral09',
                    color,
                    '&:disabled': {
                        cursor: 'not-allowed',
                        color: '$neutral06',
                    },
                }}
            />
        )
    }

    const renderIncrementButton = () => (
        <IconButtonWithTooltip
            side="bottom"
            size="38"
            iconName="zoomIn"
            tooltipText={'More seats'}
            onClick={increment}
            css={{ h: 40, w: 40, br: 999, b: '1px solid $neutral07', bg: '$neutral09', color: '$neutral04' }}
        />
    )

    // n seat|seats added|removed
    const seatsAddedText = () => {
        const seatsAdded = seatCount - seatCount
        if (seatsAdded < -1) return `${-seatsAdded} seats removed`
        if (seatsAdded === -1) return '1 seat removed'
        if (seatsAdded === 0) return '0 seats added'
        if (seatsAdded === 1) return '1 seat added'
        if (seatsAdded > 1) return `${seatsAdded} seats added`
    }

    const confirm = () => onConfirm(seatCount)

    // -----------------------------------------------------------------------------------------------------------------
    // main
    // -----------------------------------------------------------------------------------------------------------------

    const [seatCount, setSeatCount] = useState(currentSeatCount)

    const topSection = () => {
        const warning =
            '👉 This is a reminder that your account is charged prorated amount for any additional seats added during your billing cycle.'
        const currentCss = { textAlign: 'center', pt: 4, pb: 4, width: '100%' }
        const currentSeatCss = {
            ml: 16,
            mr: 16,
            p: '8px 16px 12px 16px',
            bg: '$neutral09',
            br: 6,
            b: '1px solid $neutral07',
            ai: 'center',
            w: '100%',
        }

        return (
            <FlexColumn css={{ p: 24, bg: '$neutral10', gap: 10 }}>
                <FlexRow css={{ ai: 'center', gap: 8 }}>
                    <Icon iconSize="20" name="seat" />
                    <Text css={{ fs: 18, fw: 500 }}>Manage Seats</Text>
                </FlexRow>
                <StyledSecondaryModalLabel css={{ p: 12, br: 6, b: '1px solid $neutral07', bg: '$neutral09' }}>
                    {warning}
                </StyledSecondaryModalLabel>
                <FlexRow>
                    <Text css={currentCss}>Current total seats for the {organizationName} Organization</Text>
                </FlexRow>
                <FlexRow css={{ ai: 'center' }}>
                    {renderDecrementButton()}
                    <FlexColumn css={currentSeatCss}>
                        <Text css={{ fs: 32, fw: 700 }}>{seatCount}</Text>
                        <Text css={{ color: '$neutral05', fs: 14 }}>{seatsAddedText()}</Text>
                    </FlexColumn>
                    {renderIncrementButton()}
                </FlexRow>
            </FlexColumn>
        )
    }

    // 2024.09.10 deleted "Downgrade Plan" button for now; we'll do it manually now
    const buttonSection = () => {
        return (
            <StyledModalActions>
                <Button css={{ h: 40, ml: 'auto' }} variant="secondary" size="lg" onClick={onCancel}>
                    Cancel
                </Button>
                <Button css={{ h: 40 }} variant="primary" size="lg" onClick={confirm}>
                    Confirm
                </Button>
            </StyledModalActions>
        )
    }

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer css={{ bg: '$neutral10', w: 560 }}>
                {topSection()}
                {buttonSection()}
            </StyledModalContainer>
        </StyledOverlay>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// AdminViewSubscriptionBlock
// ---------------------------------------------------------------------------------------------------------------------

const AdminViewSubscriptionBlock = ({
    organizationName,
    plan,
    seatCount,
    billingCycle,
    monthlyPrice,
    annualPrice,
    nextPaymentTimestamp,
    admins,
    collaborators,
    changeSeatCount,
    downgrade,
    upgrade,
}) => {
    const plural = (count, suffix) => (count <= 1 ? `${count} ${suffix}` : `${count} ${suffix}s`)

    const changeSeatCountAndClose = count => {
        closeModal()
        if (count !== seatCount) changeSeatCount(count)
    }

    const downgradeAndClose = () => {
        closeModal()
        downgrade()
    }

    const upgradeAndClose = (billingCycle, seatCount) => {
        closeModal()
        upgrade(billingCycle, seatCount)
    }

    const rightAlignCss = fw => ({ textAlign: 'right', fw })

    const renderBlockForTeamOrganization = () => {
        const metrics = pricingMetrics(plan, billingCycle, monthlyPrice, annualPrice, seatCount)
        const { isDiscounted, percentageDiscount, finalPrice, perSeat } = metrics

        const billingText = `${formatUsd(finalPrice)} billed ${
            isDiscounted ? `annually (${percentageDiscount}% discount)` : 'monthly'
        }`

        return (
            <FlexColumn css={mainCss}>
                <FlexRow>
                    <FlexColumn>
                        <FlexRow css={{ gap: 10, ai: 'center' }}>
                            <Text>Range {plan} Plan</Text>
                            <Button css={outlineButtonCss} variant="manage" size="md" onClick={openModal}>
                                Manage
                            </Button>
                            <Button css={outlineButtonCss} variant="manage" size="md">
                                Billing History
                            </Button>
                            <Button variant="primary" size="md">
                                Add Card
                            </Button>
                        </FlexRow>
                        <Text css={{ fw: 700, fs: 32 }}>${perSeat}</Text>
                        <Text css={{ fw: 400, color: '$neutral05', fs: 14 }}>per seat per month</Text>
                    </FlexColumn>
                    <FlexColumn css={{ ml: 'auto', ...rightAlignCss(700) }}>
                        <Text css={{ fw: 400, fs: 14, color: '$neutral05' }}>
                            Next invoice on{' '}
                            {nextPaymentTimestamp.toLocaleDateString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </Text>
                        <Text css={{ fw: 700, fs: 32, color: '$neutral04' }}>{seatCount} Seats</Text>
                        <Text css={{ fw: 400, fs: 14, color: '$neutral05' }}>{billingText}</Text>
                        <Text></Text>
                    </FlexColumn>
                </FlexRow>

                <FlexRow css={{ pt: 16, gap: 16 }}>
                    <Box css={innerBoxCss}>
                        <Text css={smallTextCss}>Total Seats</Text>
                        <FlexRow css={{ ai: 'center' }}>
                            <Text css={largeTextCss}>{seatCount}</Text>
                            <Button css={outlineButtonCss} variant="manage" size="md" onClick={openModal}>
                                Manage
                            </Button>
                        </FlexRow>
                    </Box>
                    <Box css={innerBoxCss}>
                        <Text css={smallTextCss}>Assigned Seats</Text>
                        <FlexRow css={{ ai: 'center' }}>
                            <Text css={{ fs: 32 }}>{assignedSeats}</Text>
                            <FlexColumn css={{ ml: 4 }}>
                                <Text css={{ fs: 12, fw: 500 }}>{adminString}</Text>
                                <Text css={{ fs: 12, fw: 500 }}>{collaboratorString}</Text>
                            </FlexColumn>
                        </FlexRow>
                    </Box>
                    <Box css={innerBoxCss}>
                        <Text css={smallTextCss}>Available Seats</Text>
                        <FlexRow css={{ ai: 'center' }}>
                            <Text css={largeTextCss}>{unassignedSeats}</Text>
                            <Button css={outlineButtonCss} variant="manage" size="md" onClick={openModal}>
                                Manage
                            </Button>
                        </FlexRow>
                    </Box>
                </FlexRow>
            </FlexColumn>
        )
    }

    const renderBlockForFreeOrganization = () => (
        <FlexColumn css={mainCss}>
            <FlexRow>
                <FlexColumn>
                    <FlexRow css={{ gap: 10, ai: 'center' }}>
                        <Text>Range {plan} Plan</Text>
                        <Button css={outlineButtonCss} variant="primary" size="md" onClick={openModal}>
                            Upgrade to Team
                        </Button>
                    </FlexRow>
                    <Text css={{ fw: 700, fs: 32 }}>$0</Text>
                </FlexColumn>
            </FlexRow>

            <FlexRow css={{ pt: 16, gap: 16 }}>
                <Box css={innerBoxCss}>
                    <Text css={smallTextCss}>Total Seats</Text>
                    <FlexRow css={{ ai: 'center' }}>
                        <Text css={largeTextCss}>{seatCount}</Text>
                    </FlexRow>
                </Box>
                <Box css={innerBoxCss}>
                    <Text css={smallTextCss}>Assigned Seats</Text>
                    <FlexRow css={{ ai: 'center' }}>
                        <Text css={{ fs: 32 }}>{assignedSeats}</Text>
                        <FlexColumn css={{ ml: 4 }}>
                            <Text css={{ fs: 12, fw: 500 }}>{adminString}</Text>
                            <Text css={{ fs: 12, fw: 500 }}>{collaboratorString}</Text>
                        </FlexColumn>
                    </FlexRow>
                </Box>
                <Box css={innerBoxCss}>
                    <Text css={smallTextCss}>Available Seats</Text>
                    <FlexRow css={{ ai: 'center' }}>
                        <Text css={largeTextCss}>{unassignedSeats}</Text>
                    </FlexRow>
                </Box>
            </FlexRow>
        </FlexColumn>
    )

    const renderModal = () =>
        isPaid ? (
            <AdminViewManageSubscriptionModal
                organizationName={organizationName}
                currentSeatCount={seatCount}
                assignedSeats={assignedSeats}
                onCancel={closeModal}
                onConfirm={changeSeatCountAndClose}
                onDowngrade={downgradeAndClose}
            />
        ) : (
            <AdminViewUpgradePage
                annualPrice={annualPrice}
                monthlyPrice={monthlyPrice}
                onClose={closeModal}
                onContinueToPayment={upgradeAndClose}
            />
        )

    // -----------------------------------------------------------------------------------------------------------------
    // main
    // -----------------------------------------------------------------------------------------------------------------
    // css
    const mainCss = {
        w: '100%',
        br: 5,
        b: '1px solid $neutral07',
        p: 16,
        bg: '$neutral09',
        color: '$neutral04',
        fs: 18,
        fw: 500,
        fontName: 'inter',
    }
    const outlineButtonCss = { bc: '$neutral05' }
    const smallTextCss = { fs: 14, fw: 400, color: '$neutral05' }
    const largeTextCss = { fs: 32, mr: 'auto', fw: 700 }
    const innerBoxCss = {
        b: '1px solid $neutral07 !important',
        p: '12px 20px 12px 16px',
        w: '33%',
        br: 6,
        background: '$neutral10',
    }

    const adminString = plural(admins, 'Admin')
    const collaboratorString = plural(collaborators, 'Collaborator')

    const { get: showModal, set: openModal, unset: closeModal } = useBooleanState(false)
    const assignedSeats = admins + collaborators
    const unassignedSeats = seatCount - assignedSeats
    const isPaid = plan !== RangePlans.Free

    return (
        <>
            {showModal() && renderModal()}
            {isPaid ? renderBlockForTeamOrganization() : renderBlockForFreeOrganization()}
        </>
    )
}

AdminViewManageSubscriptionModal.propTypes = {
    organizationName: PropTypes.string.isRequired,
    currentSeatCount: PropTypes.number.isRequired,
    assignedSeats: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDowngrade: PropTypes.func.isRequired,
}

AdminViewSubscriptionBlock.propTypes = {
    organizationName: PropTypes.string.isRequired,
    plan: PropTypes.oneOf(['Free', 'Team', 'Enterprise']).isRequired,
    seatCount: PropTypes.number.isRequired,
    admins: PropTypes.number.isRequired,
    collaborators: PropTypes.number.isRequired,
    nextPaymentTimestamp: PropTypes.instanceOf(Date).isRequired,
    billingCycle: PropTypes.oneOf(['Annual', 'Monthly']).isRequired,
    monthlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    changeSeatCount: PropTypes.func.isRequired,
    downgrade: PropTypes.func.isRequired,
    upgrade: PropTypes.func.isRequired,
}

export { AdminViewSubscriptionBlock, AdminViewManageSubscriptionModal }
