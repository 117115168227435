import { RadioGroupItem } from '@radix-ui/react-radio-group'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
    Box,
    Button,
    FlexColumn,
    FlexRow,
    IconButtonWithTooltip,
    Link,
    RadioGroup,
    Text,
} from '../components-reusable/index.js'
import { BillingCycles, formatUsd, pricingMetrics, RangePlans } from '../helpers.js'
import { styled } from '../range-theme/index.js'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100%',
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'absolute',
    zIndex: 100,
    color: '$neutral04',
})

const StyledPrimaryLabel = styled(Box, {
    w: 'auto',
    c: '$neutral04',
    fs: 32,
    fw: 700,
})

const StyledRadioButton = styled(RadioGroupItem, {
    alignItems: 'center',
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: '$neutral05',
    cursor: 'pointer',
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 400,
    height: 38,
    justifyContent: 'space-between',
    minHeight: 38,
    transitionDuration: '0.4s',

    '&:hover': {
        color: '$neutral03',
        backgroundColor: '$primary02',
    },

    // handle "checked" value of Radio button
    "&[data-state='checked']": {
        backgroundColor: '$primary02',
        color: '$neutral04',
        border: '1px solid $primary04',
    },
})

const AdminViewUpgradePage = ({ monthlyPrice, annualPrice, onClose, onContinueToPayment }) => {
    const continueToPayment = () => onContinueToPayment(billingCycle, seatCount)

    const increment = () => setSeatCount(seatCount + 1)
    const decrement = () => seatCount > 3 && setSeatCount(seatCount - 1)

    const renderTitleRow = () => (
        <FlexRow css={{ w: '100%' }}>
            <FlexColumn css={{ width: 'inherit' }}>
                <Button onClick={onClose} css={{ width: '70px', mb: '24px' }} variant="secondary" size="lg">
                    <Text>Cancel</Text>
                </Button>
                <StyledPrimaryLabel>Upgrade to Range "Team" Plan</StyledPrimaryLabel>
                <Link css={{ textDecoration: 'underline', c: '$primary', fw: 400, fs: 14, pt: 8 }}>
                    Learn about our plans
                </Link>
            </FlexColumn>
        </FlexRow>
    )

    const renderDecrementButton = () => {
        const cantDecrement = seatCount <= 3
        const color = cantDecrement ? '$neutral06' : '$neutral04'
        const tooltip = cantDecrement ? 'Range Premium requires a minimum of 3 seats' : 'Fewer seats'
        return (
            <IconButtonWithTooltip
                disabled={cantDecrement}
                side="bottom"
                size="38"
                iconName="zoomOut"
                tooltipText={tooltip}
                onClick={decrement}
                css={{ h: 40, w: 40, br: 999, b: standardBorder, bg: '$neutral09', color }}
            />
        )
    }

    const renderIncrementButton = () => (
        <IconButtonWithTooltip
            side="bottom"
            size="38"
            iconName="zoomIn"
            tooltipText={'More seats'}
            onClick={increment}
            css={{ h: 40, w: 40, br: 999, b: standardBorder, bg: '$neutral09', color: '$neutral04' }}
        />
    )

    const renderBillingCycleRow = () => (
        <FlexRow>
            <Text css={circledNumberCss}>1</Text>
            <FlexColumn>
                <Text css={{ fw: 700, fs: 20, pb: 8 }}>Billing Cycle</Text>
                <RadioGroup value={billingCycle} onValueChange={changeBillingCycle}>
                    <FlexRow css={{ ai: 'center', cursor: 'pointer' }}>
                        <StyledRadioButton value={BillingCycles.Annual}>
                            Annual
                            <Text
                                css={{
                                    b: '1px solid $green03',
                                    fw: 500,
                                    fs: 12,
                                    ml: 4,
                                    p: '2px 8px 2px 8px',
                                    br: 999,
                                    background: '$green02',
                                    color: '$neutral04',
                                }}
                            >
                                {percentageDiscount}% off
                            </Text>
                        </StyledRadioButton>
                    </FlexRow>
                    <StyledRadioButton value={BillingCycles.Monthly} css={{ ml: 16 }}>
                        Monthly
                    </StyledRadioButton>
                </RadioGroup>
            </FlexColumn>
        </FlexRow>
    )

    const renderNumberOfSeatsRow = () => {
        const seatCountCss = {
            fs: 32,
            fw: 700,
            w: 100,
            p: '0 12px',
            b: standardBorder,
            display: 'inline-flex',
            justifyContent: 'center',
            br: 6,
            backgroundColor: '$neutral09',
        }

        return (
            <FlexRow>
                <Text css={circledNumberCss}>2</Text>
                <FlexColumn>
                    <Text css={{ fw: 700, fs: 20, pb: 4 }}>Choose Number of Seats</Text>
                    <Text css={{ fw: 400, fs: 14, w: 455, color: '$neutral04' }}>
                        Paid seats may be assigned and reassigned to any member, and you can assign them Collaborator or
                        Admin roles. Guests are free users with limited permissions.
                    </Text>
                    <FlexRow css={{ ai: 'center', mt: 16, gap: 16 }}>
                        {renderDecrementButton()}
                        <Text css={seatCountCss}>{seatCount}</Text>
                        {renderIncrementButton()}
                    </FlexRow>
                </FlexColumn>
            </FlexRow>
        )
    }

    const renderOrderSummary = () => {
        const renderDetailsRow = () => (
            <FlexRow css={{ bb: standardBorder, bt: standardBorder, mt: 10, mb: 10, pt: 10, pb: 10 }}>
                <FlexColumn>
                    <Text css={{ fw: 700 }}>{`Range Team Plan - ${billingCycle}`}</Text>
                    <Text css={{ fw: 500 }}>{totalAmountText}</Text>
                    {isDiscounted && <Text css={{ fw: 500 }}>Annual discount ({percentageDiscount}%)</Text>}
                </FlexColumn>
                <FlexColumn css={{ ml: 'auto' }}>
                    <Text>&nbsp;</Text>
                    <Text css={rightAlignCss(700)}>{formatUsd(basePrice)}</Text>
                    {isDiscounted && <Text css={rightAlignCss(500)}>{formatUsd(discount)}</Text>}
                </FlexColumn>
            </FlexRow>
        )

        const renderTotalRow = () => (
            <FlexRow css={{ mt: 10, mb: 10 }}>
                <Text css={{ fw: 700 }}>Total Due</Text>
                <Text css={{ ...rightAlignCss(700), ...{ ml: 'auto' } }}>{formatUsd(finalPrice)}</Text>
            </FlexRow>
        )

        const rightAlignCss = fw => ({ textAlign: 'right', fw })

        return (
            <FlexColumn
                css={{
                    b: standardBorder,
                    p: 24,
                    br: 6,
                    minWidth: 500,
                    fs: 14,
                    backgroundColor: '$neutral09',
                    height: 'fit-content',
                }}
            >
                <Text css={{ fw: 700 }}>Order Summary</Text>
                {renderDetailsRow()}
                {renderTotalRow()}
                <Button variant="primary" size="lg" css={{ ml: 'auto', mt: 8 }} onClick={continueToPayment}>
                    Continue to Payment
                </Button>
            </FlexColumn>
        )
    }

    const standardBorder = '1px solid $neutral07'
    const circledNumberCss = {
        fw: 700,
        fs: 20,
        mr: 12,
        pl: 8,
        w: 32,
        h: 32,
        bg: '$neutral09',
        b: standardBorder,
        br: 999,
    }

    const [billingCycle, changeBillingCycle] = useState(BillingCycles.Annual)
    const [seatCount, setSeatCount] = useState(3)

    const metrics = pricingMetrics(RangePlans.Team, billingCycle, monthlyPrice, annualPrice, seatCount)
    const { isDiscounted, percentageDiscount, basePrice, discount, finalPrice } = metrics

    const totalAmountText =
        billingCycle === BillingCycles.Annual
            ? `${formatUsd(monthlyPrice)} x ${seatCount} seats x 12 months`
            : `${formatUsd(monthlyPrice)} x ${seatCount} seats`

    return (
        <StyledOuterContainer>
            <FlexColumn css={{ w: '80%', maxWidth: 1024, p: '80px 0 0 0px', gap: 32 }}>
                {renderTitleRow()}
                <FlexRow css={{ gap: 40 }}>
                    <FlexColumn css={{ gap: 24 }}>
                        {renderBillingCycleRow()}
                        {renderNumberOfSeatsRow()}
                    </FlexColumn>
                    {renderOrderSummary()}
                </FlexRow>
            </FlexColumn>
        </StyledOuterContainer>
    )
}

AdminViewUpgradePage.propTypes = {
    monthlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onContinueToPayment: PropTypes.func.isRequired,
}

export default AdminViewUpgradePage
