import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { pick, without } from '@range.io/functional'
import { FlexColumn, FlexRow, ScrollArea, SelectMenu, Text, Icon } from '../components-reusable/index.js'
import Followers from './Followers.js'
import UserRow from './UserRow.js'
import { ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'

const ItemsGroup = ({ items, multiSelect, onItemClick, onCheckboxClick, selected }) => {
    if (!items) return null
    return items.map(option => {
        // We have to double check if the item is selected, because even if item doesn't belong to the selected items group, the user might have just selected it.
        // When user selects or unselects items we don't recalculate the filteredItems, because we don't want to shuffle the list when user is clicking on the items.
        // We only recalculate the filteredItems when user closes the dropdown.
        const isItemActuallySelected = selected?.includes(option.value)

        return (
            <SelectMenu.Option
                key={option.value}
                value={option.value}
                multiSelect={multiSelect}
                onItemClick={onItemClick}
                onCheckboxClick={onCheckboxClick}
                isSelected={isItemActuallySelected}
            >
                <UserRow participantShape={option.participantShape} isOnline={option?.isOnline} />
            </SelectMenu.Option>
        )
    })
}

const FollowersSelector = React.forwardRef(
    (
        { followers, participants = [], preselectedFollowers = [], multiSelect = false, onFollowerSelected },
        contentRef
    ) => {
        const isControlled = followers && onFollowerSelected
        const [selected, setSelected] = useState(isControlled ? followers : preselectedFollowers)
        const [phraseToFilter, setPhraseToFilter] = useState('')
        const [openIndex, setOpenIndex] = useState(0)

        useEffect(() => {
            if (followers) setSelected(followers)
        }, [followers])

        const handleItemSelected = value => {
            if (isControlled) onFollowerSelected(value)
            else {
                const shouldRemove = selected?.includes(value)
                setSelected(shouldRemove ? without(value, selected) : [...selected, value])
            }
        }

        const clearSearchInput = () => setPhraseToFilter('')
        const handleClickOutside = () => {
            clearSearchInput()
        }
        const handleItemClicked = value => {
            clearSearchInput()
            handleItemSelected(value)
        }
        const handleItemCheckboxClicked = value => handleItemSelected(value)
        const onOpenChange = open => {
            // Make sure we record whenever the dropdown closes, as it triggers some list recalculations.
            if (!open) setOpenIndex(openIndex + 1)
        }

        // Recalculate the followers list only when these change:
        //  - participants - original followers array has changed
        //  - phraseToFilter - search input value has changed, meaning user wants to narrow down the results
        //  - openIndex - this will be changed when the list was closed.
        //    We don't recalculate the groups right away when the selected items change, because we don't want to shuffle the list immediately.
        const filteredItems = useMemo(() => {
            // Categorize items based on their selected state
            const categorizeBySelection = (option, accumulator) => {
                if (selected?.includes(option.value)) {
                    accumulator.selectedItems.push(option)
                } else {
                    accumulator.notSelectedItems.push(option)
                }
            }

            return participants.reduce(
                (acc, option) => {
                    // If there's no filter phrase, categorize all
                    if (!phraseToFilter.length) {
                        categorizeBySelection(option, acc)
                    } else {
                        // If there's a filter phrase, first check if it matches
                        const fullName = option.participantShape.fullName.toLowerCase()
                        const email = option.participantShape.email
                        const filter = phraseToFilter.toLowerCase()
                        if (fullName.includes(filter) || email.includes(filter)) categorizeBySelection(option, acc)
                    }
                    return acc
                },
                { selectedItems: [], notSelectedItems: [] }
            )
        }, [phraseToFilter, participants, openIndex])

        const followersAvatars = useMemo(() => {
            if (!selected.length) return []
            const avatars = []
            selected.forEach(id => {
                const matchingParticipant = participants.find(participant => participant.value === id)
                if (!matchingParticipant) return
                const avatar = pick(['id', 'avatarFallbackText', 'avatarUrl'], matchingParticipant.participantShape)
                avatars.push(avatar)
            })

            return avatars
        }, [selected, participants])

        const followerCount = selected.length

        return (
            <SelectMenu.Root
                options={participants}
                phraseToFilter={phraseToFilter}
                selectedValues={selected}
                onInteractOutside={handleClickOutside}
                onOpenChange={onOpenChange}
                triggerElement={
                    <SelectMenu.Trigger
                        tooltipText={
                            <div style={{ textAlign: 'center' }}>
                                <span style={{ fontWeight: 'bold', marginBottom: 4 }}>
                                    Edit Followers ({followerCount})
                                </span>
                                <br />
                                <span style={{ fontSize: 12 }}>Followers receive notifications</span>
                            </div>
                        }
                    >
                        <Followers followers={followersAvatars} />
                    </SelectMenu.Trigger>
                }
                ref={contentRef}
            >
                <ScrollArea css={{ overflowY: 'auto' }}>
                    <FlexColumn css={{ m: 4, gap: 2 }}>
                        <FlexRow css={{ gap: 4, alignItems: 'center', color: '$neutral04' }}>
                            <Icon name="accountUserAdd" iconSize="20" />
                            <Text css={{ fontSize: 16 }}>Followers ({followerCount})</Text>
                        </FlexRow>
                        <Text css={{ fontSize: 14, color: '$neutral05', mb: 6 }}>
                            Followers receive notification updates.
                        </Text>
                    </FlexColumn>
                    <SelectMenu.Input
                        placeholder="Search for users..."
                        value={phraseToFilter}
                        onChange={setPhraseToFilter}
                    />
                    <ItemsGroup
                        items={filteredItems.selectedItems}
                        multiSelect={multiSelect}
                        onItemClick={handleItemClicked}
                        onCheckboxClick={handleItemCheckboxClicked}
                        selected={selected}
                    />

                    {filteredItems.selectedItems.length && filteredItems.notSelectedItems.length ? (
                        <SelectMenu.Separator />
                    ) : null}

                    <ItemsGroup
                        items={filteredItems.notSelectedItems}
                        multiSelect={multiSelect}
                        onItemClick={handleItemClicked}
                        onCheckboxClick={handleItemCheckboxClicked}
                        selected={selected}
                    />
                </ScrollArea>
            </SelectMenu.Root>
        )
    }
)

FollowersSelector.propTypes = {
    participants: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            participantShape: PropTypes.shape(ParticipantShapePropTypes).isRequired,
            isOnline: PropTypes.bool,
        })
    ),
    preselectedFollowers: PropTypes.arrayOf(PropTypes.string), // only values (ids) from participants array
    followers: PropTypes.arrayOf(PropTypes.string), // only values (ids) from participants array
    multiSelect: PropTypes.bool,
    onFollowerSelected: PropTypes.func,
}

export default FollowersSelector
