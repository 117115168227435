import { TaskListFilterSettings } from '@range.io/basic-types'
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { Checkbox, FlexColumn, FlexRow, Icon, Text, TextInput, Tooltip } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { TaskListFilterSelector } from './DropdownFilter.js'
import FiltersActiveBanner from './FiltersActiveBanner.js'
import { LIST_VIEW_SORT_TYPES } from './ListView.js'
import ListViewBulkActions from './ListViewBulkActions'

const ListViewHeaderWrapper = styled(FlexColumn, {
    color: '$neutral04',

    fontSize: '14px',
    lineHeight: '21px',
    borderBottom: '1px solid $neutral07',
    ai: 'center',
})

const ControlsContainer = styled(FlexRow, {
    width: '100%',
    alignItems: 'center',
    padding: '16px 16px 16px 16px',
    gap: '16px',
    height: '100%',
})

const StyledSearchInputWrapper = styled('div', {
    position: 'relative',
    width: 315,
    height: 40,
    padding: 0,

    input: {
        paddingLeft: '32px',
        fontWeight: 500,
        '&:hover': { cursor: 'pointer' },
    },
})

const StyledSearchIcon = styled(Icon, {
    position: 'absolute',
    zIndex: '1',
    top: 8,
    left: 6,
})

const StyledTableHeaderCss = styled('div', {
    ai: 'center',
    height: '32px',
    minHeight: '32px',
    gap: '12px',
    width: 'stretch',
    borderTop: '1px solid $neutral07',
    background: '$neutral09',
    pl: 16,
    pr: 16,
    display: 'grid',
    variants: {
        showFullContent: {
            true: {
                gridTemplateColumns: '14px 20px minmax(350px, 1fr) 100px 180px 140px 250px 60px 30px 100px',
            },
            false: {
                gridTemplateColumns: '14px 20px minmax(350px, 1fr) 100px  180px 140px 250px',
            },
        },
    },
})

const StyledTableHeaderLabel = styled(FlexRow, {
    gap: '3px',
    mr: 'auto',
    ai: 'center',
    cursor: 'pointer',
    color: '$neutral05',
    p: '4px 6px 4px 6px',
    ml: -6,
    br: 6,

    '&:hover': { color: '$neutral04', background: '$neutral07' },
})

const StyledSecondaryTableLabel = styled(Text, {
    width: 'auto',
    fs: 12,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
})

const TableHeaderColumn = ({ label, onSortClick, type, sortSettings, tooltipText }) => {
    const isSortedByThisColumn = !!sortSettings && sortSettings?.field === type
    const isAscending = isSortedByThisColumn && sortSettings?.isAscending

    const iconCss = {
        transform: !isAscending ? 'rotate(180deg)' : false,
    }

    const handleSortClick = () => onSortClick({ type, isAscending: isSortedByThisColumn ? isAscending : undefined })

    return (
        <Tooltip tooltipText={tooltipText} side="bottom" align="center">
            <StyledTableHeaderLabel data-cy={`task-list-header-button-${type}`} onClick={handleSortClick}>
                <StyledSecondaryTableLabel>{label}</StyledSecondaryTableLabel>
                {isSortedByThisColumn && <Icon css={iconCss} name="chevronDown" iconSize="16" />}
            </StyledTableHeaderLabel>
        </Tooltip>
    )
}

const ListViewHeader = ({
    handleSearchPhraseChanged,
    checkedItems,
    clearCheckedItems,
    onDeleteItems,
    onArchiveItems,
    allAvailableAssigneesShapes,
    bulkActionSelectedAssignee,
    onBulkActionSelectedAssignee,
    areAllCheckedTasksArchived,
    areAllTasksFollowed,
    isFollowingInProgress,
    statusNames,
    bulkActionSelectedStatus,
    onBulkActionSelectedStatus,
    onBulkActionTagsChange,
    onBulkActionDateChange,
    onBulkFollowButtonClick,
    showFullContent,
    allTaskNames,
    items,
    onCheckAllItemsClick,
    onSortClick,
    sortSettings,
    hasRightsToDelete,
    hasRightsToArchive,
    onExport,
    ...restProps
}) => {
    const { getState, dispatch } = useStore()

    const taskListFilterSettings = useSelector(ReduxSelectors.taskListFilterSettings)
    const setTaskListFilterSettings = settings => dispatch(ReduxActions.taskListFilterSettingsChanged(settings))

    const isProjectDataLoaded = ReduxSelectors.isProjectDataLoaded(getState())

    const {
        canvases: allCanvasShapes,
        statuses: allStatusShapes,
        tags: allTagShapes,
        projectParticipants: allParticipantShapes,
        collaborations: collaborationShapes,
    } = useSelector(ReduxSelectors.allShapes)

    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const projectIdentifier = selectedProject?.identifier
    const allIdentifiers = collaborationShapes
        .filter(c => !!c.isTask)
        .flatMap(collaboration => collaboration.identifier)

    const taskListFilterProps = {
        allCanvasShapes,
        allStatusShapes,
        allTagShapes,
        allParticipantShapes,
        allIdentifiers,
        projectIdentifier,
        filterSettings: taskListFilterSettings,
        setFilterSettings: setTaskListFilterSettings,
    }
    const isFilterApplied = TaskListFilterSettings.isSetToDefaults(
        taskListFilterSettings,
        allStatusShapes,
        allParticipantShapes
    )

    const resetFilterSettings = () =>
        setTaskListFilterSettings(TaskListFilterSettings.defaultFilterSettings(allStatusShapes, allParticipantShapes))

    const hasLoadedFilterSettings = Object.keys(taskListFilterSettings).length
    const areItemsPartiallyChecked = checkedItems.length && checkedItems.length !== items.length

    return (
        <ListViewHeaderWrapper {...restProps}>
            <ControlsContainer>
                <Text css={{ fontSize: 20, fontWeight: 700, marginRight: 'auto' }}>Task List</Text>

                <StyledSearchInputWrapper>
                    <StyledSearchIcon iconSize="24" css={{ color: '$neutral05' }} name="search" />
                    <TextInput
                        data-cy="list-view-search-input"
                        placeholder="Search for tasks..."
                        onChange={handleSearchPhraseChanged}
                    />
                </StyledSearchInputWrapper>

                {!!hasLoadedFilterSettings && (
                    <TaskListFilterSelector {...taskListFilterProps} isDataLoaded={isProjectDataLoaded} />
                )}
            </ControlsContainer>
            {isProjectDataLoaded && !isFilterApplied && (
                <FiltersActiveBanner variant="taskList" onResetClick={resetFilterSettings} />
            )}
            {!!checkedItems.length && (
                <ListViewBulkActions
                    hasRightsToArchive={hasRightsToArchive}
                    hasRightsToDelete={hasRightsToDelete}
                    checkedItems={checkedItems}
                    onClearAllClick={clearCheckedItems}
                    onDeleteItems={onDeleteItems}
                    onArchiveItems={onArchiveItems}
                    allAvailableAssigneesShapes={allAvailableAssigneesShapes}
                    bulkActionSelectedAssignee={bulkActionSelectedAssignee}
                    onBulkActionSelectedAssignee={onBulkActionSelectedAssignee}
                    areAllCheckedTasksArchived={areAllCheckedTasksArchived}
                    areAllTasksFollowed={areAllTasksFollowed}
                    isFollowingInProgress={isFollowingInProgress}
                    statusNames={statusNames}
                    bulkActionSelectedStatus={bulkActionSelectedStatus}
                    onBulkActionSelectedStatus={onBulkActionSelectedStatus}
                    onBulkActionTagsChange={onBulkActionTagsChange}
                    onBulkActionDateChange={onBulkActionDateChange}
                    onBulkFollowButtonClick={onBulkFollowButtonClick}
                    onExport={onExport}
                />
            )}
            <StyledTableHeaderCss showFullContent={showFullContent}>
                <Checkbox
                    size="md"
                    onClick={onCheckAllItemsClick}
                    checked={items.length === checkedItems.length}
                    partiallyChecked={areItemsPartiallyChecked}
                    data-cy="list-view-header-checkbox"
                />
                <div />
                <TableHeaderColumn
                    label={`Task Name (${items.length} total)`}
                    type={LIST_VIEW_SORT_TYPES.taskName}
                    onSortClick={onSortClick}
                    sortSettings={sortSettings}
                    tooltipText="Sort by name"
                />
                <TableHeaderColumn
                    label="ID"
                    type={LIST_VIEW_SORT_TYPES.identifier}
                    onSortClick={onSortClick}
                    sortSettings={sortSettings}
                    tooltipText="Sort by identifier"
                />
                <TableHeaderColumn
                    label="Assignee"
                    onSortClick={onSortClick}
                    type={LIST_VIEW_SORT_TYPES.assigneeName}
                    sortSettings={sortSettings}
                    tooltipText="Sort by assignee"
                />
                <TableHeaderColumn
                    label="Due Date"
                    onSortClick={onSortClick}
                    type={LIST_VIEW_SORT_TYPES.dueDate}
                    sortSettings={sortSettings}
                    tooltipText="Sort by due date"
                />
                <TableHeaderColumn
                    label="Location"
                    onSortClick={onSortClick}
                    type={LIST_VIEW_SORT_TYPES.locationName}
                    sortSettings={sortSettings}
                    tooltipText="Sort by location"
                />
                {showFullContent && (
                    <>
                        <div />
                        <div />
                        <TableHeaderColumn
                            label="Created On"
                            onSortClick={onSortClick}
                            type={LIST_VIEW_SORT_TYPES.creationDate}
                            sortSettings={sortSettings}
                            tooltipText="Sort by created on date"
                        />
                    </>
                )}
            </StyledTableHeaderCss>
        </ListViewHeaderWrapper>
    )
}

export default ListViewHeader
