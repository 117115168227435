/*
 * CanvasSource
 *
 * A single Canvas can have multiple presentations -- mainly for maps (eg. Satellite, Street, DroneDeploy, etc)
 *
 * There are really two kinds of Canvas: PDF and non-PDF. A PDF canvas will have a single PDF CanvasSource,
 * which includes the PDF information; a non-PDF could have many CanvasSources
 *
 * NOTE: for now, existing Canvases have NO CanvasSources, because we introduced the idea of CanvasSource much later.
 * We will eventually retrofit our existing projects so that they have CanvasSources. For the existing deprecated,
 * we create a CanvasSource for it when reading from Firestore by replacing the "old" Canvas definition with a
 * lot of fields to a new Canvas with just id and name and move the other data to a CanvasSource.Pdf
 *
 *
 * CanvasSource - a presentation of a single canvas
 */
import { mergeRight, pick, renameKeys, taggedSum } from '@range.io/functional'
import { timestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const CanvasSource = taggedSum('CanvasSource', {
    Pdf: {
        id              : StringTypes.Id,
        canvasId        : StringTypes.Id,
        type            : /pdf/,
        name            : 'String',
        validFrom       : 'Object?', // date
        validTo         : 'Object?', // date
        userDefinedScale: 'Number',
        storageVersion  : 'Object?',
        pdfUrl          : StringTypes.OptionalUrl, // result of calling Firebase Storage getDownloadUrl
        // when first loading a PDF to create a Project, we don't want to upload the PDF before the user has pressed
        // "save" so the canvasSource may have the Pdf object from the file they selected instead
        temporaryPdf    : 'Object?',
        
        isGeolocated    : 'Boolean',
        center          : '[Number]',
        rotation        : 'Number'
        
        
    },
    Mapbox: {
        id              : StringTypes.Id,
        canvasId        : StringTypes.Id,
        type            : /mapbox/,
        name            : 'String',
        validFrom       : 'Object?', // date
        validTo         : 'Object?', // date
        zoom            : 'Number',
        center          : '[Number]', //  Lng and Lat both Number
        styleUrl        : 'String',
    },
    DroneDeploy: {
        id              : StringTypes.Id,
        canvasId        : StringTypes.Id,
        type            : /dronedeploy/,
        name            : 'String',
        validFrom       : 'Object?', // date
        validTo         : 'Object?', // date
        zoom            : 'Number',
        center          : '[Number]', //  Lng and Lat both Number
        styleUrl        : 'String',
        tilesetUrl      : 'String',
    },
})

CanvasSource.Pdf.prototype.renameFieldButLoudly('canvas', 'canvasId')
CanvasSource.Mapbox.prototype.renameFieldButLoudly('canvas', 'canvasId')
CanvasSource.DroneDeploy.prototype.renameFieldButLoudly('canvas', 'canvasId')

CanvasSource.from = o => {
    if (o.type === 'pdf') return CanvasSource.Pdf.from(o)
    if (o.type === 'mapbox') return CanvasSource.Mapbox.from(o)
    if (o.type === 'dronedeploy') return CanvasSource.DroneDeploy.from(o)

    throw new Error("Don't understand CanvasSource type")
}

CanvasSource.Pdf.update = (canvasSource, changes) => CanvasSource.Pdf.from(mergeRight(canvasSource, changes))
CanvasSource.Mapbox.update = (canvasSource, changes) => CanvasSource.Mapbox.from(mergeRight(canvasSource, changes))
CanvasSource.DroneDeploy.update = (canvasSource, changes) =>
    CanvasSource.DroneDeploy.from(mergeRight(canvasSource, changes))

CanvasSource.update = (canvasSource, changes) =>
    canvasSource.match({
        Pdf: () => CanvasSource.Pdf.update(canvasSource, changes),
        Mapbox: () => CanvasSource.Mapbox.update(canvasSource, changes),
        DroneDeploy: () => CanvasSource.DroneDeploy.update(canvasSource, changes),
    })

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------
const pdfFromFirebase = o =>
    CanvasSource.Pdf.from({
        id: o.id,
        canvasId: o.canvas,
        type: 'pdf',
        name: o.name,
        validFrom: timestampToDate(o.validFrom),
        validTo: timestampToDate(o.validTo),

        // map rotation
        isGeolocated: o.isGeolocated,
        userDefinedScale: o.userDefinedScale,
        center: o.center,
        rotation: o.rotation,
    })

const mapboxFromFirebase = o =>
    CanvasSource.Mapbox.from({
        id: o.id,
        canvasId: o.canvas,
        type: 'mapbox',
        name: o.name,
        validFrom: timestampToDate(o.validFrom),
        validTo: timestampToDate(o.validTo),
        zoom: o.zoom,
        center: o.center,
        styleUrl: o.styleUrl,
    })

const droneDeployFromFirebase = o =>
    CanvasSource.DroneDeploy.from({
        id: o.id,
        canvasId: o.canvas,
        type: 'dronedeploy',
        name: o.name,
        validFrom: timestampToDate(o.validFrom),
        validTo: timestampToDate(o.validTo),
        zoom: o.zoom,
        center: o.center,
        styleUrl: o.styleUrl,
        tilesetUrl: o.tilesetUrl,
    })

/*
 * Create a CanvasSource from a JSON object
 * @sig fromFirebase :: {k:v} -> CanvasSource
 */
CanvasSource.fromFirebase = o => {
    if (o.type === 'pdf') return pdfFromFirebase(o)
    if (o.type === 'mapbox') return mapboxFromFirebase(o)
    if (o.type === 'dronedeploy') return droneDeployFromFirebase(o)

    throw new Error("Don't understand CanvasSource type")
}

/*
 * TODO: handle validFrom/validTo
 */
const pdfToFirebase = o =>
    pick(
        ['id', 'canvas', 'type', 'name', 'userDefinedScale', 'isGeolocated', 'center', 'rotation'],
        renameKeys({ canvasId: 'canvas' }, o)
    )

const mapboxToFirebase = o =>
    pick(['id', 'canvas', 'center', 'styleUrl', 'type', 'name', 'zoom'], renameKeys({ canvasId: 'canvas' }, o))

const droneDeployToFirebase = o => {
    throw new Error('droneDeployToFirebase not implemented')
}

CanvasSource.Pdf.toFirebase = pdfToFirebase
CanvasSource.Mapbox.toFirebase = mapboxToFirebase
CanvasSource.DroneDeploy.toFirebase = droneDeployToFirebase

CanvasSource.toFirebase = canvasSource =>
    canvasSource.match({
        Pdf: pdfToFirebase,
        Mapbox: mapboxToFirebase,
        DroneDeploy: droneDeployToFirebase,
    })

// ---------------------------------------------------------------------------------------------------------------------
// Computations
// ---------------------------------------------------------------------------------------------------------------------

const metersPerInch = 0.0254
const pointsPerInch = 72
const metersPerPoint = metersPerInch / pointsPerInch

CanvasSource.dimensionsInMeters = (canvasSource, widthInPoints, heightInPoints) => {
    const { userDefinedScale } = canvasSource
    return {
        width: widthInPoints * userDefinedScale * metersPerPoint,
        height: heightInPoints * userDefinedScale * metersPerPoint,
    }
}

/*
 * Given a Canvas Source return max zoom value for rendering
 * @sig getMaxZoom :: CanvasSource -> Number
 */
CanvasSource.getDefaultZoom = canvasSource =>
    canvasSource.match({
        Pdf: () => 19.8,
        Mapbox: () => 18,
        DroneDeploy: () => 18,
    })

/*
 * Given a Canvas Source return max zoom value for rendering
 * @sig getMaxZoom :: CanvasSource -> Number
 */
CanvasSource.getMaxZoom = canvasSource =>
    canvasSource.match({
        Pdf: () => 24,
        Mapbox: () => 24,
        DroneDeploy: () => 24,
    })

/*
 * Given a Canvas Source return min zoom value for rendering
 * @sig getMinZoom :: CanvasSource -> Number
 */
CanvasSource.getMinZoom = canvasSource =>
    canvasSource.match({
        Pdf: () => 18,
        Mapbox: () => 2,
        DroneDeploy: () => 2,
    })

export default CanvasSource
